import * as React from "react";

import Layout from "../components/layout/layout";
import Seo from "../components/seo";
import ProfessionalTrainingComponent from "../components/use_case/ProfessionalTraining";
import { useTranslation } from 'react-i18next';

const ProfessionalTraining = () => {
  const {t} = useTranslation();
  return (
    <>
      <Seo
        title={`${t('professional_training')}`}
        description={t('get_better_results_with_flexible')}
      />
      <Layout>
        <ProfessionalTrainingComponent />
      </Layout>
    </>
  );
};

export default ProfessionalTraining;
