import React from "react";

import Ready from "../ready/ready";
// import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Exam from "../exam/exam";
import Latest from "../latest/latest";
// import { Link } from "gatsby"
import UseCaseTopBanner from "../top_banner/product_top_banner";
import Charts from "../charts/charts";
import Logos from "../logos-section/logos";
import { useTranslation } from 'react-i18next';

const ProfessionalTraining = () => {
  const {t} = useTranslation();
  const exams = [
    {
      title: `${t('reduced_training_fees')}`,
      description:
        `${t('train_your_teams_quickly')}`,
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/professional-recruitment_exams_1.png",
    },
    {
      title: `${t('analysis_and_understanding')}`,
      description:
        `${t('understand_the_strengths_and_training')}`,
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/flexible 2.png",
    },
    {
      title: `${t('be_consistent')}`,
      description:
        `${t('innovative_teams_always_work_better')}`,
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/AI_exams_2.png",
    },
    {
      title: `${t('a_hyper_flexible_solution')}`,
      description:
        `${t('offer_your_employees_and_yourself')}`,
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/five_stars_screen_with_people.svg",
    },
  ];

  const LatestData = {
    title1: "Découvrez notre ",
    title2: "dernière publication",
    description:
      `${t('what_is_remote_assessment_proctoring')}`,
    btnText: `${t('to_consult')}`,
  };

  const ChartData={title:["TonAvenir utilise Mereos"," pour former ses franchisés"],contexte:`${t('tonAvenir_is_the_largest_network')}`,Résultats:[{heading:" 3 jours",desc:`${t('to_start_the_first_evaluation_session')}`},{heading:"50%",desc:`${t('time_saving_for_trainers')}`},{heading:"4,9  ",desc:`${t('average_score_given_by_candidates')}`}],points:[`${t('quality')}`,"Analyse  ",`${t('reputation')}`,`${t("autonomy")}`]}

  // const breakpoints = useBreakpoint();
  return (
    <div className={`conatiner font-sans`}>
      <UseCaseTopBanner
        pageTitle={`${t('professional_training')}`}
        title={t('measure_to_better_train_your_teams')}
        description={t('reduce_training_time_and_costs')}
        imageSrc="https://d2lxkizvrhu4im.cloudfront.net/images/professional-training.svg"
        rightArrowImageSrc="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow_right_white_icon.svg"
        leftButton={{
          text: `${t("free_trials")}`,
          href: "/free-trial",
        }}
        rightButton={{
          text: `Demo >`,
          href: "/demo",
        }}
        bannerType="useCase"
      />

      {/* logos  */}
      <Logos />

      <div className={`mx-auto w-11/12 lg:w-5/6 `}>
        <div className="my-20">
          <Exam page="prof_training" Data={exams} title={t('why_is_mereos_right_for_you')} />
        </div>
      </div>

      <div className={`mx-auto w-11/12 lg:w-5/6`}>
        {/* Latest from Source */}
        <Latest data={LatestData} />

        {/* Charts */}
        <Charts ChartData={ChartData}/>
      </div>

      {/* Ready */}
      <div className="flex flex-row mt-10 mb-20">
        <img
          alt="right star"
          src="https://d2lxkizvrhu4im.cloudfront.net/icons/left_star_icon.svg"
          class="hidden md:block self-end"
        />
        <div className="mt-10 mx-auto w-11/12 lg:w-5/6">
          <Ready />
        </div>

        <img
          alt="left star"
          src="https://d2lxkizvrhu4im.cloudfront.net/icons/left_star_icon.svg"
          class="hidden md:block self-start"
        />
      </div>
    </div>
  );
};

export default ProfessionalTraining;
